import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c3d9f58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "faquestion" }
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["d-flex justify-content-between align-items-center", _ctx.view ? 'faquestion-btn' : '']),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.viewHandler && _ctx.viewHandler(...args)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.question) + " ", 1),
      (_openBlock(), _createElementBlock("svg", null, [
        _createElementVNode("use", {
          "xlink:href": `${require('@/assets/icons.svg')}#icon-down`
        }, null, 8, _hoisted_2)
      ]))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["faquestion-question", _ctx.view ? 'faquestion-question-open' : ''])
    }, _toDisplayString(_ctx.answer), 3)
  ]))
}

import { defineComponent } from "vue";

export default defineComponent({
  name: "faq-question",
  props: { question: String, answer: String },
  data() {
    return {
      view: false,
    };
  },
  methods: {
    viewHandler() {
      this.view = !this.view;
    },
  },
});

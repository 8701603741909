
import FaqQuestion from "@/components/FaqQuestion.vue";
import Layout from "@/Layout/Layout.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Layout, FaqQuestion },
  name: "FAQ",
  mounted() {
    window.scrollTo({ top: 0 });
  },
});
